import { AppRouter } from "@/server/api/routers/_app";
import { auth } from "@/services/firebase/firebase";
import { queryClient } from "@/services/queryClient";
import { httpBatchLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import superjson from "superjson";

const getAuthToken = async () =>
  `Bearer ${await auth.currentUser?.getIdToken()}`;

export const trpc = createTRPCNext<AppRouter>({
  config(opts) {
    return {
      transformer: superjson,
      queryClient,
      links: [
        httpBatchLink({
          url: "/api/trpc",
          async headers() {
            return {
              authorization: await getAuthToken(),
              "x-project-id": "cms-princess",
            };
          },
        }),
      ],
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
});
