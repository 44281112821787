import { useAuth } from "@/contexts/auth";
import { useRouter } from "next/router";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { projects } from "@/settings";
import { ProjectSettings, ProjectSlug } from "@/settings/types";
import { useNotificationContext } from "@/contexts/notification";
import { LoadingScreen } from "@/components/common/LoadingScreen";
import { useAvailableProjects } from "@/hooks/project/useAvailableProjects";

type ProjectContextType = {
  project: ProjectSettings | null;
  isProjectCmsDomain: boolean;
};

const ProjectContext = createContext<ProjectContextType>({
  project: null,
  isProjectCmsDomain: false,
});

// Handle project from domain name & project by query value in url
// @todo refactor, to only handle projectFromHost & projectFromQuery, return current project only
// @todo separate input other hooks, like useAvailableProject,...
export const ProjectProvider: FC<{
  projectFromHost?: ProjectSettings;
}> = ({ children, projectFromHost }) => {
  const { project, gqlClientReady, loading, isFromHost } =
    useGetProject(projectFromHost);

  if (loading || !gqlClientReady) {
    return <LoadingScreen text="Loading Project" />;
  }

  return (
    <ProjectContext.Provider
      value={{
        project,
        isProjectCmsDomain: isFromHost,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProject = () => useContext(ProjectContext);

const useGetProject = (projectFromHost: ProjectSettings | undefined) => {
  const { pathname } = useRouter();
  const { projectQuery, gqlClientReady } = useProjectQuery();
  const availableProjects = useAvailableProjects();
  const { user } = useAuth();

  const projectFromQuery = useMemo(() => {
    const pj = projects.find(({ slug }) => slug === projectQuery) ?? null;
    return pj;
  }, [projectQuery]);

  const project = projectFromHost ?? projectFromQuery;
  const isFromHost = !!projectFromHost;

  const { runFnAfterInvalidProjects, runFnAfterValidProjects } =
    useProjectFollowingAction({ project, isFromHost, projectQuery });

  const currentProject = useMemo(() => {
    if (!user) return null; //if user is null, auth.tsx will handle the redirection

    if (!project) {
      runFnAfterInvalidProjects();
      return null;
    }

    if (availableProjects.length === 0) {
      runFnAfterInvalidProjects();
      return null;
    }

    const isAvailableToUser = !!availableProjects.find(
      (userAvailableProject) => userAvailableProject.slug === project.slug,
    );

    if (isAvailableToUser) {
      runFnAfterValidProjects();
      return project;
    }

    runFnAfterInvalidProjects();

    return null;
  }, [
    runFnAfterInvalidProjects,
    runFnAfterValidProjects,
    availableProjects,
    project,
    user,
  ]);

  return {
    project: currentProject,
    gqlClientReady,
    loading: projectQuery && !currentProject && pathname !== "/signIn",
    isFromHost,
  };
};

const useProjectQuery = () => {
  const { query, pathname } = useRouter();
  const [ready, setReady] = useState(false);

  const projectQuery = query.project
    ? (query.project as string)
    : projects
        .map((el) => el.slug)
        .includes(pathname.split("/")[1] as ProjectSlug)
    ? pathname.split("/")[1]
    : undefined;

  //for setting up new gql client
  useEffect(() => {
    setReady(false);
    setTimeout(() => {
      setReady(true);
    }, 500);
  }, [projectQuery]);

  return { projectQuery, gqlClientReady: ready };
};

const useProjectFollowingAction = ({
  project,
  isFromHost,
  projectQuery,
}: {
  project: ProjectSettings | null;
  projectQuery?: string;
  isFromHost: boolean;
}) => {
  const { openNotification } = useNotificationContext();
  const { signOut } = useAuth();
  const { replace } = useRouter();

  const runFnAfterValidProjects = useCallback(() => {
    if (!isFromHost || !project) return;

    if (!projectQuery || projectQuery !== project.slug) {
      replace(`/${project.slug}/dashboard`);
      return;
    }
  }, [isFromHost, project, replace, projectQuery]);

  const runFnAfterInvalidProjects = useCallback(() => {
    if (isFromHost) {
      openNotification({ message: "Access Denied", variant: "danger" });
      signOut(true);
      return;
    }

    if (projectQuery && !project) {
      replace("/princess/dashboard");
      openNotification({ message: "Invalid Project URL", variant: "danger" });
      return;
    }

    if (project) {
      replace("/princess/dashboard");
      openNotification({ message: "Access Denied", variant: "danger" });
      return;
    }
  }, [openNotification, isFromHost, project, projectQuery, replace, signOut]);

  return { runFnAfterValidProjects, runFnAfterInvalidProjects };
};
